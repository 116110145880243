<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        :class="{ row, error: v$.stationSelect.$errors.length }"
                        :label="$t('forms.station')"
                        label-for="h-station"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-station"
                            v-model="stationSelect"
                            :options="stations"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stationSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.storage.stockItemLocation.$errors.length }"
                        :label="$t('forms.stockItemLocation')"
                        label-for="stockItemLocation"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-textarea v-model="storage.stockItemLocation" id="stockItemLocation"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.storage.stockItemLocation.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>

import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";

export default {
    components: {
        vSelect
    },
    props: {
        storageObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getResourceList(this.$Stations, this.stations)
        if ("id" in this.storage) {
            this.stationSelect = {
                label: this.storage.station.name,
                id: this.storage.station['@id'],
            };
        }
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            stationSelect: {required},
            storage: {
                stockItemLocation: {required}
            },
        };
    },
    data() {
        return {
            storage: this.storageObject,
            stations: [],
            stationSelect: {}
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            const isValid = await this.v$.$validate();
            if (isValid) {
                this.storage.station = this.stationSelect.id;
                if (this.action === "add") {
                    this.create(
                        this.$StockItemLocations,
                        this.storage,
                        this.$t("messages.stockItemLocationCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (this.action === "update") {
                    this.$emit("clearAction");
                    this.update(
                        this.$StockItemLocations,
                        this.storage.id,
                        this.storage,
                        this.$t("messages.stockItemLocationUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                }
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/storage-locations`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

